
.instructions__wrapper h1,
.aims-scopes__wrapper h1 {
    color: var(--primary-color);
    font-size: 44px;
    font-family: 'Calibri Bold';
}

.instruction-content__container {
    display: flex;
    justify-content: space-between;
}

.instruction-sections {
    margin: 15px 0;
}

.instruction-sections ul {
   list-style-position: inside;
}

.instruction-sections li {
    color: #4C795C;
    font-family: 'Calibri Bold';
    margin-bottom: 10px;
    font-size: 20px;
}

.instruction-sections li span {
   text-decoration: none;
   color: #4C795C;
   cursor: pointer;
}

.page-content__wrapper {
    padding: 40px 0;
    margin-bottom: 450px;
}

.page-content__container {
    width: 90%;
}

.page-content__container .content-item {
    padding: 30px 0;
}

.content-item {
    border-top: 3px solid #2492E2;
    text-align: justify;
}

.content-item h5 {
    font-family: 'Calibri Bold';
    color: var(--primary-color);
    font-size: 32px;
    margin-bottom: 20px;
}

.content-item p,
.aims-scopes__container p,
.area-item li,
.instruction-sections.journal p {
    font-size: 20px;
    line-height: 35px;
}

.content-item span {
    font-family: 'Calibri Bold';
}

.content-item:last-of-type h5:not(:first-of-type) {
    margin-top: 30px;
}

/* EDITORIAL PAGE */

.editorial-content__container {
    display: flex;
    justify-content: space-between;
    width: 90%;
    border-bottom: 3px solid #2492E2;
    padding-bottom: 15px;
}
.editorial-item {
    margin-top: 40px;
}

.editorial-item h5 {
  font-size: 25px;
  font-family: 'Calibri Bold';
  margin-bottom: 10px;
}

.editorial-item p {
    font-size: 25px;
    line-height: 40px;
}

.editorial-content__wrapper {
    width: 90%;
    padding: 0px 0 15px 0;
    margin-bottom: 500px;
}

.editorial__container {
    padding-bottom: 15px;
    border-bottom: 3px solid #2492E2;
}

/* JOURNAL SECTION */

.journal-volume__wrapper {
    padding: 30px 0;
}

.journal-volume__container {
    display: flex;
    justify-content: space-between;
}

.volume-section__one {
    width: 60%;
}

.volume-section__one .journal-name__wrapper {
    padding: 0;
}

.volume-section__one .journal__container h4 {
    font-size: 30px;
}

.volume-section__one p {
    color: #494E96;
    font-size: 20px;
    line-height: 40px;
    margin-top: 40px;
}

.volume-section__two {
    width: 25%;
}

.volume-card {
    width: 100%;
    height: 400px;
}

.volume-card img {
    height: 100%;
}

.card-top {
    background-color: #545AE8;
    padding: 20px;
}

.card-top h5 {
    color: var(--bg-color);
    font-family: 'Calibri Bold';
    font-size: 24px;
    margin-bottom: 10px;
}

.card-top p {
    text-align: center;
    color: var(--bg-color);
}

.card-bottom {
    background-color: #6C24E2;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-bottom h5 {
    color: var(--bg-color);
    font-family: 'Calibri Bold';
    font-size: 24px;
}

.volume-section__two h2 {
    font-family: 'Calibri Bold';
    margin-top: 20px;
    text-align: justify;
    color: #474F9A;
    font-size: 22px;
}

.volume-section__two h2 a {
  text-decoration: none;
  color: #474F9A;
}

/* AIMS AND SCOPES */

.aims-scopes__wrapper {
    padding: 60px;
}

.aims-scopes__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.aims-scopes__container p {
    width: 60%;
    text-align: justify;
}

.aims-area__container {
    padding: 120px 0;
    display: flex;
    justify-content: space-between;
}

.area-item h5 {
    color: #494E96;
    font-family: 'Calibri Bold';
    font-size: 30px;
    margin-bottom: 10px;
}

.area-item li {
    margin-bottom: 5px;
}

/* JOURNAL OVERVIEW */
.instruction-content__container.journal {
    border-bottom: 3px solid #2492E2;
    padding-bottom: 40px;
}

.instruction-sections.journal {
    padding-bottom: 50px;
    text-align: justify;
    width: 70%;
}

.journal-content {
    padding: 30px 0;
}

.journal-content p:first-of-type {
    font-family: 'Calibri Bold';
    color: #494E96;
    margin-bottom: 30px;
}

.journal-content p {
    font-size: 20px;
    width: 60%;
    text-align: justify;
    line-height: 32px;
}

/* ARTICLES SECTION */
.articles__wrapper {
    margin: 40px 0;
}

.articles__container {
    margin-bottom: 80px;
}
.articles__header {
    border-bottom: 1px solid #000000;;
}

.header-item {
    background: #494E96;
    padding: 20px 40px;
    width: fit-content;
}

.header-item p {
    color: var(--bg-color);
    font-family: 'Calibri Bold';
    font-size: 20px;
}

.article-item {
    padding: 20px 0;
    border-bottom: 1px solid #a4a3a3;
}

.article-item .item-title {
    color: #4C795C;
    font-family: 'Calibri Bold';
    font-size: 24px;
    line-height: 35px;
    margin-bottom: 20px;
}

.article-item .item-data {
    color: #787777;
    margin-bottom: 25px;
}

.download {
    color: #494E96;
    font-family: 'Calibri Bold';
    font-size: 14px;
    text-decoration: none;
}

.download:hover {
  text-decoration: underline;
}

.view-more {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    column-gap: 10px;
    color: #494E96;
}

.view-more img {
    width: 35px;
}

.instruction-content__container.articles {
    justify-content: flex-end;
    margin-top: 50px;
}

@media screen and (max-width: 1200px) {
    .volume-section__one {
        width: 65%;
    }

    .volume-section__two {
        width: 30%;
    }
}

@media screen and (max-width: 960px) {

    .volume-section__one .journal__container h4 {
        font-size: 26px;
    }
    .volume-section__two h2 {
        font-size: 22px;
    }

    .area-item {
        width: 45%;
    }
    
    .instruction-content__container.journal {
        width: 100%;
    }

    .card-top {
        padding: 20px 16px;
    }

    .card-top h5 {
        font-size: 20px;
    }

    .card-top p {
        font-size: 14px;
    }

    .content-item p,
    .aims-scopes__container p,
    .area-item li,
    .instruction-sections.journal p,
    .volume-section__one p,
    .journal-content p {
        font-size: 16px;
        line-height: 25px;
    }

    .article-item .item-title {
        font-size: 20px;
        line-height: 30px;
    }

    .download {
        font-size: 12px;
    }

    .view-more {
        font-size: 22px;
    }
}

@media screen and (max-width: 720px) {
    .instructions__wrapper {
        padding: 0 40px;
    }

    .instruction-content__container {
        width: 100%;
    }

    .page-content__wrapper {
        padding: 40px;
    }

    .editorial-content__container {
        width: 100%;
    }

    .editorial-content__wrapper {
        width: 100%;
    }

    .journal-content p {
        width: 70%;
    }

    .content-item p,
    .aims-scopes__container p,
    .area-item li,
    .instruction-sections.journal p,
    .volume-section__one p,
    .journal-content p {
        font-size: 14px;
        line-height: 22px;
    }

    .card-top {
        padding: 20px 15px;
    }

    .card-top h5 {
        font-size: 19px;
    }

    .card-top p {
        font-size: 14px;
    }
}

@media screen and (max-width: 580px) {
    .journal-volume__wrapper {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .volume-section__one .journal__container h4 {
        font-size: 20px;
    }

    .volume-section__one p {
        margin-top: 30px;
    }

    .card-top {
        padding: 20px 12px;
    }

    .card-top h5 {
        font-size: 14px;
    }

    .card-top p {
        font-size: 10px;
    }

    .card-bottom {
        height: 80px;
    }

    .card-bottom h5 {
        font-size: 20px;
    }

    .volume-section__two h2 {
        font-size: 14px;
        margin-top: 12px;
    }

    .instructions__wrapper {
        padding: 0 20px;
    }

    .instructions__wrapper h1 {
        font-size: 30px;
    }

    .instruction-sections li {
        font-size: 16px;
    }

    .header-item {
        padding: 15px 30px;
    }

    .header-item p {
        font-size: 18px;
    }
}