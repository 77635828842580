@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

@font-face {
  font-family: 'Calibri';
  src: url('./assets/fonts/Calibri/calibril.ttf');
}

@font-face {
  font-family: 'Calibri Bold';
  src: url('./assets/fonts/Calibri/calibrib.ttf');
}

@font-face {
  font-family: 'Calibri Italic';
  src: url('./assets/fonts/Calibri/Calibri.ttf');
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #494E96;
  --secondary-color: #F0EEEE;
  --bg-color: #FFFFFF;
  --link-color: #4C795C;
  --border-color: #2492E2;
  --input-color: #83839A;
  --footer-color: #B2B3CF;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Calibri';
  scroll-behavior: smooth;
}

p,
h5,
h6,h4,h2,h1 {
  padding: 0;
  margin: 0;
}

.container {
  max-width: 1080px;
  margin: 0 auto;
}

@media screen and (max-width: 1159px) {
  .container {
    max-width: 960px;
  }
}

@media screen and (max-width: 995px) {
  .container {
    max-width: 736px;
  }
}

@media screen and (max-width: 850px) {
  .container {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* SCROLL TO TOP SECTION */
.scroll-top__wrapper {
  position: fixed; 
  width: 100%;
  left: 92%;
  bottom: 40px;
  height: 20px;
  font-size: 3rem;
  z-index: 1;
}

.scroll-top__wrapper button {
  border: none;
  background-color: var(--primary-color);
  color: #FFFFFF;
  cursor: pointer;
  width: 40px;
  height: 40px;
  transform: scale(1);
  animation: pulse-black 2s infinite;
}

@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #494e96d2;
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px #494e9600;
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #494e9600;
  }
}

/* HEADER SECTION */
.header__wrapper {
  background-color: var(--secondary-color);
  padding: 35px 0;
}

.header-content__container {
  display: flex;
}

.search-section {
  width: 60%;
  display: flex;
  justify-content: center;
}

.app-logo,
.journal-logo__section {
  width: 20%;
}

.search-section input {
  border: 3px solid #474F9A;
  box-sizing: border-box;
  border-radius: 80px;
  text-align: center;
  width: 560px;
  height: 54px;
  background-color: transparent;
}

.search-section input::placeholder {
  color: #474F9A;
  font-family: 'Poppins', sans-serif;
}

.journal-logo__section {
  display: flex;
  justify-content: flex-end;
}

.journal-logo {
  display: flex;
  align-items: center;
  column-gap: 10px;
  text-decoration: none;
}

.journal-logo h2 {
  font-size: 38px;
  height: 38px;
  color: var(--primary-color);
  font-family: 'Calibri Bold';
}

/* JOURNAL NAME SECTION */

.journal__container {
  background-color: var(--primary-color);
  width: fit-content;
  padding: 20px 24px;
}

.journal__container h4 {
  color: var(--bg-color);
  font-family: 'Calibri Bold';
  font-size: 36px;
}

/* MENU SECTION */
.menu__wrapper {
  background-color: var(--primary-color);
}

.menu__container {
  display: flex;
}

/* .form-select {
  position: relative;
}

.form-select:after {
  content: '\f078';
  font: normal normal normal 14px/1 FontAwesome;
  right: 16px;
  top: 15px;
  padding: 0 0 2px;
  position: absolute;
  pointer-events: none;
  color: var(--bg-color);
}

.menu__wrapper .form-select select {
  display: block;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  width: 160px;
  border: none;
  color: var(--bg-color);
  padding: 10px 15px;
  font-size: 18px;
  border-right: 3px solid var(--bg-color);
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
} */

.menu__links {
  display: flex;
}

.menu__links .menu-item {
  color: var(--bg-color);
  border-right: 3px solid var(--bg-color);
  padding: 10px 15px;
  font-size: 18px;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  width: 160px;
  cursor: pointer;
}

/* FOOTER SECTION */

.footer__wrapper {
  background-color: var(--primary-color);
  padding: 72px;
}

.footer-content__container {
  width: 50%;
  margin: 0 auto;
}

.footer-content__container h5 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 39px;
  color: var(--footer-color);
  text-align: center;
}

.footer-content__container form {
  margin: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}

.footer-content__container form input {
  background-color: transparent;
  border: 1px solid var(--input-color);
  border-radius: 80px;
  color: var(--input-color);
  height: 60px;
  width: 400px;
  padding: 10px 20px;
  font-size: 20px;
  line-height: 30px;
}

.footer-content__container form button {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  background: linear-gradient(105.5deg, #545AE7 19.57%, #393FCF 78.85%);
  border-radius: 60px;
  height: 60px;
  border: none;
  color: var(--bg-color);
  width: 220px;
  cursor: pointer;
}

.footer-menu {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
}

.footer-menu .menu-item {
  padding-right: 50px;
}

.footer-menu .menu-item:not(:last-of-type) {
  border-right: 1px solid #626381;
}

.footer-menu .menu-item a {
  text-decoration: none;
  color: var(--footer-color);
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
}

.copyright-section {
  margin-top: 80px;
  text-align: center;
}

.copyright-section p {
  color: var(--footer-color);
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
}

/* SIDE MENU */

.side-item {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 10px;
}

.side-item a,
.side-item div {
  color: #4C795C;
  font-size: 20px;
}

.side-item div {
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: 1350px) {
  .footer-menu .menu-item {
    padding-right: 30px;
  }
}

@media screen and (max-width: 1250px) {
  .footer-menu .menu-item {
    padding-right: 20px;
  }

  .footer-menu .menu-item a {
    font-size: 18px;
  }
}

@media screen and (max-width: 960px) {
  .search-section input {
    width: 480px;
  }

  .footer-menu .menu-item {
    padding-right: 16px;
  }

  .footer-menu .menu-item a {
    font-size: 14px;
  }

  .side-item a,
  .side-item div {
    font-size: 16px;
  }

  .footer-content__container {
    width: 60%;
  }
  .scroll-top__wrapper button {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 720px) {
  .header__wrapper {
    padding: 48px 0;
  }

  .search-section input {
    width: 300px;
  }

  .journal__container h4 {
    font-size: 26px;
  }

  .menu__wrapper .form-select select {
    width: 150px;
  }

  .side-item a,
  .side-item div {
    font-size: 14px;
  }

  .footer-content__container {
    width: 70%;
  }
}

@media screen and (max-width: 580px) {
  .header__wrapper {
    padding: 30px 0;
  }

  .search-section input {
    width: 240px;
  }

  .footer-content__container {
    width: 90%;
  }

  .menu__wrapper .form-select select {
   font-size: 16px;
   width: 130px;
   padding: 10px 12px;
  }

  .form-select:after {
    top: 12px;
  }

  .scroll-top__wrapper {
    left: 90%;
  }
}
